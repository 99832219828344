<script setup lang="ts">
import type { Job, JobWithLocations, Company } from "@/types";

const unbranded = useUnbranded();

const props = defineProps<{
  job: Job | JobWithLocations;
  company: Company;
  city?: string;
  state?: string;
}>();

const cityState = computed(() => {
  if (props.city && props.state) return `${props.city}, ${props.state}`;
  if (props.state) return props.state;
  return null;
});

const { primaryColor } = usePrimaryColor();

const companyUrl = computed(() => {
  return `${unbranded.value ? "/u" : ""}/companies/${props.company.slug}`;
});
</script>
<template>
  <div
    class="flex items-center p-6 bg-white rounded-md shadow dark:shadow-gray-700 dark:bg-slate-900"
  >
    <NuxtLink v-if="company" :to="companyUrl">
      <NuxtImg
        width="128"
        height="128"
        :src="company.logo || '/no-image-placeholder.png'"
        :alt="company.name || 'No image available'"
        class="w-16 h-16 p-1 bg-white rounded-full shadow md:p-2 md:h-28 md:w-28 dark:bg-slate-900 dark:shadow-gray-700"
      />
    </NuxtLink>

    <div class="mt-0 ms-4">
      <h1>
        <a href="#job-description">
        <span class="text-xl font-semibold">{{ job.title }}</span>
        <div class="items-center mt-2 lg:flex">
          <span class="flex items-center font-medium text-slate-400 me-2">
            <IconBuildingOfficeOutline
              class="w-4 mr-1"
              :style="{ color: primaryColor || '' }"
            />
            {{ company.name }}</span
          >

          <JobLocationLabel
            v-if="!unbranded"
            class="text-slate-400"
            :job="job"
            :primary-state="state"
            :primary-city="city"
            :loadLocations="true"
          />

          <span
            v-if="unbranded && cityState"
            class="flex items-center text-base font-medium text-slate-400"
          >
            <IconMapPinOutline
              class="w-4 mr-1"
              :style="{ color: primaryColor || '' }"
            />
            {{ cityState }}
          </span>
        </div>
      </a>
      </h1>
    </div>
  </div>
</template>
