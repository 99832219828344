<script setup lang="ts">
import type { Job, Company, Location, Form } from "@/types";

const route = useRoute();
const unbranded = useUnbranded();
const { formVariantsABTest, descriptionBeforeFormVsAfterFormABTest } =
  useABTests();

const requestUrl = useRequestURL();

// These requests MUST be made in sequence
// the company must be fetched first to get the company id
// this is because the job endpoint requires the company id
// and sometimes the company id isn't provided in the route
// this is the case for custom domains
console.time("fetch company");

const companyUrl = computed(() => {
  return route.params.companyslug
    ? `/api/companies/bySlugFromCache/${route.params.companyslug}`
    : `/api/companies/byDomainFromCache/${requestUrl.hostname}`;
});

const { data: company } = await useFetch<Company>(companyUrl);
console.timeEnd("fetch company");

if (!company.value) throw error404("Client not found");

console.time("fetch job");
const { data: job } = await useFetch<
  Job & {
    client: Company;
    locations: Location[];
    form: Form;
  }
>(`/api/jobs/${route.params.id}`, {
  query: {
    clientId: company.value.id,
    published: route.query.published ?? true,
    include: "form",
  },
});
console.timeEnd("fetch job");

if (!job.value) throw error404("Job not found");

const metaTitle = `${job.value.title} | ${company.value.name}${
  unbranded.value ? "" : " | Hire Truckers"
}`;
const metaDescription = ref(
  job.value.description && job.value.description.length > 160
    ? job.value.description.slice(0, 160) + "..."
    : job.value.description
);

useSeoMeta({
  title: metaTitle,
  ogTitle: metaTitle,
  description: metaDescription.value,
  ogDescription: metaDescription.value,
});

useCanonicalUrl();

const img = useImage();

useHead({
  link: () => {
    if (!company.value?.logo || !unbranded.value) return [];
    return [
      {
        rel: "icon",
        type: "image/png",
        href: img(company.value.logo, {
          width: 32,
        }),
      },
    ];
  },
});

const { primaryColor, setPrimaryColor } = usePrimaryColor();
setPrimaryColor(company?.value?.primaryColor);

const showForm = ref(false);
onMounted(async () => {
  await nextTick();
  showForm.value = true;
});

const showContent = ref(
  descriptionBeforeFormVsAfterFormABTest.value.showBefore
);
onMounted(async () => {
  await nextTick();
  if (!showContent.value) {
    showContent.value = descriptionBeforeFormVsAfterFormABTest.value.showBefore;
  }
});

const showFullDescription = ref(false);
</script>

<template>
  <template v-if="company && job">
    <section
      class="py-16 bg-slate-50 dark:bg-slate-800"
      :class="{
        'md:py-24 py-16': !unbranded,
        'py-8': unbranded,
      }"
    >
      <JobHeading
        class="block md:hidden"
        :job="job"
        :company="company"
        :city="($route.query.city as string)"
        :state="($route.query.state as string)"
      />
      <div class="container mt-5 md:max-w-full lg:max-w-[1600px]">
        <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
          <div
            class="lg:col-span-8 md:col-span-6"
            :class="{
              'order-1': descriptionBeforeFormVsAfterFormABTest.showBefore,
              'order-2 md:order-1':
                descriptionBeforeFormVsAfterFormABTest.showAfter,
            }"
          >
            <JobHeading
              class="hidden md:flex"
              :job="job"
              :company="company"
              :city="($route.query.city as string)"
              :state="($route.query.state as string)"
            />
            <div
              :class="{
                hidden: !showContent,
                'md:block': true,
              }"
            >
              <div
                :class="{
                  'h-56 md:h-auto overflow-hidden fade-bottom':
                    descriptionBeforeFormVsAfterFormABTest.showBefore &&
                    !showFullDescription,
                }"
              >
                <JobDescription
                  v-if="job.description && company.id"
                  :description="job.description"
                  :clientId="company.id"
                />
              </div>
              <div class="text-center">
                <button
                  v-if="
                    !showFullDescription &&
                    descriptionBeforeFormVsAfterFormABTest.showBefore
                  "
                  class="btn btn-outline btn-neutral btn-sm md:hidden"
                  @click="showFullDescription = !showFullDescription"
                >
                  Read Full Description
                </button>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="relative order-1 lg:col-span-4 md:col-span-6">
            <JobPhoneNumber />
            <div
              v-if="!showForm"
              class="flex justify-center items-center h-40 md:hidden"
            >
              <AppLoader />
            </div>
            <Transition name="form">
              <div
                v-if="showForm"
                class="sticky top-20 bg-white rounded-md shadow dark:shadow-gray-700 dark:bg-slate-900"
              >
                <div v-if="job?.form">
                  <LazyJobFormCustom
                    :company="company"
                    :job="job"
                    @loaded="showContent = true"
                  />
                </div>
                <div v-else>
                  <LazyJobFormFull
                    v-if="formVariantsABTest.showFull"
                    :company="company"
                    :job="job"
                    @loaded="showContent = true"
                  />
                  <LazyJobFormStepsAlt
                    v-else-if="formVariantsABTest.showStepsAlt"
                    :company="company"
                    :job="job"
                    @loaded="showContent = true"
                  />
                  <LazyJobFormSteps
                    v-else
                    :company="company"
                    :job="job"
                    @loaded="showContent = true"
                  />
                </div>
              </div>
            </Transition>
          </div>
          <!--end col-->
        </div>
        <!--end grid-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
  </template>
</template>

<style scoped>
:deep(button.btn:not(.btn-outline)) {
  @apply block w-full border-none;
  background-color: v-bind(primaryColor);
}
.tab {
  @apply font-bold;
}

.fade-in-left {
  animation-name: fadeInLeft;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.fade-in-right {
  animation-name: fadeInRight;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

:deep(.prose li::before) {
  color: v-bind(primaryColor);
}
:deep(.prose h1) {
  margin-top: 1.5rem;
}

.form-enter-active,
.form-leave-active {
  transition: all 1s ease;
}

.form-enter-from,
.form-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.fade-bottom {
  position: relative;
}
.fade-bottom::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4rem; /* Height of the fade effect */

  background: linear-gradient(to bottom, transparent, #f7fafc);
}

@media (min-width: 768px) {
  .fade-bottom::after {
    display: none;
  }
}
</style>
