<script setup lang="ts">
const unbranded = useUnbranded();
</script>
<template>
  <TheNavbar>
    <div :class="unbranded ? 'layout-unbranded' : 'layout-branded'">
      <slot />
    </div>
  </TheNavbar>
</template>
<style>
@import "@/assets/frontend-styles/index.css";
@import "@/assets/front-back-styles/index.css";

/* Scroll margin top for all elements to account for the sticky navbar */
.layout-branded * {
  scroll-margin-top: 80px;
}

.layout-unbranded * {
  scroll-margin-top: 10px;
}
</style>
